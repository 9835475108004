/* eslint-disable max-len */
import React from 'react'

import Layout from '../../Layout'
import SEO from '../../SEO'

import { Container, Title, P } from '../../components/Legals'

const Legal = () => (
  <Layout withPadding={false} restricted={false}>
    <SEO title="Politique de remboursement" />
    <Container>
      <P><Title>RETOURS</Title></P>
      <P> </P>
      <P>----</P>
      <P> </P>
      <P>Notre politique dure 30 jours. Si 30 jours se sont écoulés depuis votre achat, nous ne pouvons malheureusement pas vous offrir un remboursement ou un échange.</P>
      <P> </P>
      <P>Pour pouvoir bénéficier d’un retour, votre article doit être inutilisé et dans le même état où vous l’avez reçu.</P>
      <P> </P>
      <P>Les carte-cadeaux ne peuvent pas être retournées.</P>
      <P> </P>
      <P>Pour effectuer un retour, vous devez nous présenter une preuve d’achat.</P>
      <P> </P>
      <P>Il y a certaines situations où seul un remboursement partiel est accordé: (le cas échéant)</P>
      <P>* Tout article qui n’est pas dans son état d’origine, qui est endommagé ou qui présente certaines pièces manquantes pour des raisons qui ne sont pas dues à une erreur de notre part.</P>
      <P>* Tout article qui est retourné plus de 30 jours après la livraison.</P>
      <P> </P>
      <P>
        <Title>Remboursements</Title>
        {' '}
        (le cas échéant)
      </P>
      <P>Une fois que nous aurons reçu et inspecté l’article retourné, nous vous enverrons un e-mail pour vous confirmer que nous l’avons bien reçu. Nous vous informerons également de notre décision quant à l’approbation ou au rejet de votre demande de remboursement.</P>
      <P>Si votre demande est approuvée, alors votre remboursement sera traité, et un crédit sera automatiquement appliqué à votre carte de crédit ou à votre méthode originale de paiement, dans un délai de 30 jours. Nous ferons tout de même au plus vite.</P>
      <P> </P>
      <P>
        <Title>Remboursements en retard ou manquants</Title>
        {' '}
        (le cas échéant)
      </P>
      <P>Si vous n’avez pas encore reçu votre remboursement, veuillez d’abord consulter votre compte bancaire à nouveau.</P>
      <P>Ensuite, contactez l’entité émettrice de votre carte de crédit, car il pourrait y avoir un délai avant que votre remboursement ne soit officiellement affiché.</P>
      <P>Ensuite, contactez votre banque. Il y a souvent un délai de traitement nécessaire avant qu’un remboursement ne soit affiché.</P>
      <P>Si après avoir effectué toutes ces étapes, vous n’avez toujours pas reçu votre remboursement, veuillez s’il vous plaît nous contacter à shop@oldtree.fr.</P>
      <P> </P>
      <P>
        <Title>Articles soldés</Title>
        {' '}
        (le cas échéant)
      </P>
      <P>Seuls les articles à prix courant peuvent être remboursés intégralement. Les articles soldés seront remboursés à leur prix d’achat effectif. Il en va de même si un coupon de réduction a été utilisé lors de l’achat.</P>
      <P> </P>
      <P>
        <Title>Échanges</Title>
        {' '}
        (le cas échéant)
      </P>
      <P>Nous remplaçons un article seulement s’il est défectueux ou endommagé. Si dans ce cas vous souhaitez l’échanger contre le même article, envoyez-nous un e-mail à shop@oldtree.fr et nous vous recontacterons.</P>
      <P>Si vous avez acheté un article textile et que vous vous êtes trompé.e de taille, les frais d’expédition seront à votre charge.</P>
      <P> </P>
      <P><Title>Expédition</Title></P>
      <P>Les frais de retours et de réexpédition seront à la charge de la partie fautive. Nous, si un article venait à être défectueux, vous si vous vous êtes trompé.e lors de votrecommande.</P>
      <P>En fonction de l’endroit où vous vivez, le délai nécessaire pour recevoir votre produit échangé peut varier.</P>
      <P> </P>
      <P>Vous pourriez envisager d’utiliser un service de livraison qui vous permet de suivre l’envoi ou de souscrire à une assurance de livraison. Nous ne garantissons pas que nous recevrons l’article que vous nous retournez.</P>
    </Container>
  </Layout>
)

export default Legal
